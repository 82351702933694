.Login-container {
  background: url("media/background.png") no-repeat;
}

.login-grid {
  min-height: 100vh;
}

@media (min-width: 600px) {
  .Main-container {
    margin-top: 84px;
  }
}

@media (min-width: 0px) and (orientation: landscape) {
  .Main-container {
    margin-top: 68px;
  }
}

.Main-container {
  margin-top: 76px;
}

.clickable {
  cursor: pointer;
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 8px;
}

.content {
  flex-grow: 1;
  padding: 24px;
}

.bold {
  font-weight: bold;
}

.Loading-indicator {
  height: 100vh;
}

.Box-loading-indicator {
  height: 100%;
}

.Facebook-login-button {
  background-color: #4267B2;
  color: #ffffff;
}

.fixedHeaderTable {
  height: 80vh;
  width: 100vw;
}
